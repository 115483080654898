<template>
   <v-container>
     <div class="item__column item__100 app">

       
     
        <div class="app__header item__row item__ac item__100 item__jb">
            <div class="app__logo item__row item__ac">
              <img src="../assets/img/loan.png" alt="">
              <p class="app__title">Qaryz kitap</p>
            </div>
            <i class="mdi mdi-account app__account" @click="$router.push('/login')"></i>
        </div>


        <div class="app__section item__row item__ac">
            <i class="mdi mdi-function mr-2 "></i>
            <p class="app__subtitle">Функции</p>
        </div>

        <div class="functions  item__row">
            <div class="functions__item item__row item__ac" v-for="(item,index) in functions" :key="index">
                    <i class="mr-2" :class="item.icon"></i>
                    <p>{{item.name}}</p>
            </div>
        </div>
        
        <div class="app__section item__row item__ac">
            <i class="mdi mdi-check-circle-outline mr-2 "></i>
            <p class="app__subtitle">Какие проблемы решает qaryzkitap</p>
        </div>


        <div class="functions  item__row">
            <div class="functions__item item__abs" v-for="(item,index) in plusses" :key="index">
                    <i class="mr-2" :class="item.icon"></i>
                    <p>{{item.name}}</p>
            </div>
        </div>

<!--        <div class="pulsingButton item__row item__ac" @click="openWhatsapp()">-->
<!--            <i class="mdi mdi-whatsapp mr-2"></i>-->
<!--            <p>Оставить заявку</p>-->
<!--        </div>-->
    </div>
   </v-container>
</template>

<script>
export default {
    components: {
        
    },
  data() {
    return {
        functions: [
            {
                name: 'Указание срока возврата',
                icon: 'mdi mdi-update'
            },
            {
                name: 'Частичное погашение',
                icon: 'mdi mdi-cash-multiple'
            },
            {
                name: 'Напоминание о наступлении сроков возврата',
                icon: 'mdi mdi-bell'
            },
            {
                name: 'Список людей с агрегацией всех долгов',
                icon: 'mdi mdi-order-alphabetical-ascending'
            },
            {
                name: 'История долгов',
                icon: 'mdi mdi-history'
            },
            {
                name: 'Выгрузка всех данных в excel',
                icon: 'mdi mdi-file-excel'
            },
        ],
        plusses: [
              {
                  name: 'Учет долгов',
                  icon: 'mdi mdi-cash-plus'
              },
              {
                  name: 'Быстрый поиск по имени,телефону,сумме',
                  icon: 'mdi mdi-magnify'
              },
              {
                  name: 'Напоминание о просроченных долгов',
                  icon: 'mdi mdi-bell'
              },
        ]
    };
  },
  methods: {
    openWhatsapp() {
      window.open('http://wa.me/77078709561','_blank')
    }
  },
  mounted() {
  },
  beforeMount() {
      
  },
  watch: {
     
  },
};
</script>

<style lang="scss">

 .app
 __title {
    font-size: 30px;
    margin-bottom: 50px;
 }
 .app__subtitle {
    font-size: 20px;
    margin-bottom: 30px;
 }

 .app__title {
  font-size: 28px;
  font-weight: bold;
 }

    .functions::-webkit-scrollbar {
        height: 5px;
    }
    .functions::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: transparent;
    }
    .functions::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 2px;
        height: 5px !important;
        width: 5px !important;
    }

 .functions {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 30px;
    padding: 10px 0;

    .functions__item {
        
        min-width: 250px;
        text-align: center;
        margin-right: 20px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
         background: linear-gradient(90deg, rgba(22,43,214,1) 10%, rgba(33,144,200,0.9458158263305322) 79%, rgba(0,212,255,1) 100%);
        color: white;
        i {
            font-size: 30px;
        }
        p {
            font-size: 16px;
        }
    }
 }

 /* Basic button styling */

.pulsingButton {
  cursor: pointer;
  justify-content: center;
  min-width: 300px;
  text-align: center;
  white-space: nowrap;
  padding: 10px;
  box-shadow: 0 0 0 0 #00d4ff;
  border-radius: 30px;
   background: linear-gradient(90deg, rgba(22,43,214,1) 10%, rgba(33,144,200,0.9458158263305322) 79%, rgba(0,212,255,1) 100%);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 16px;
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;

  p {
    margin-bottom: 0;
  }
  i {
    margin-bottom: 0;
  }
}


/* Comment-out to have the button continue to pulse on mouseover */

a.pulsingButton:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  color: #ffffff;
}


/* Animation */

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}



.app {
  .app__section {
      i {
        font-size: 26px;
        color: #535252;
      }
      p {
        color: #535252;
      }
  }
  .app__header {
    margin-bottom: 50px;
  }
  .app__logo {
    img {
      width: 60px;
    }
    p {
      color: #535252;
    }
  }
  .app__account {
    cursor: pointer;
    font-size: 28px;
  }
  .app__account:hover {
    opacity: 0.8;
  }
}
 
</style>
